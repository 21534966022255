import React from 'react';

import withBg from '../helpers/withBg';
import Layout from '../components/layout';
import SEO from '../components/seo';
import TrustpilotHorizontal from '../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../components/Sidebar/SidebarStaticPage';
import RedMorelyImg from '../components/Image/RedMorelyImg';
import './styles.css';
import GetCallBackForm from '../components/GetCallBackForm/GetCallBackForm';

const data = {
  title: 'Refer and earn at travelopod',
  seoTitle: 'Refer and earn at travelopod | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class referAndEarnAtTravelopod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <h1>Refer and Earn Program</h1>
                <p>Refer Travelopod & earn $50 for each successful referral.</p>
                <p>Simply fill out the below form to claim your reward</p>
                <a href="https://docs.google.com/forms/d/13FP911lv8ivj3ctsrPs06lUfg9DUB-DxNR5jq88ERfw/viewform?edit_requested=true">
                  Refer & Earn - Google Forms
                </a>
                {/* <GetCallBackForm /> */}
                <h2>Terms and Conditions</h2>
                <ul>
                  <li>
                    At the time of the booking, the referral is required to
                    inform about him/her being a referral and provide the
                    details.
                  </li>
                  <li>
                    The referral must not be already in communications with
                    Travelopod for you to be eligible for the reward.
                  </li>
                  <li>
                    There are no limits to the number of referrals you can send
                    and earn.
                  </li>
                  <li>
                    The reward must be claimed within 45 days of the referral
                    booking.
                  </li>
                  <li>
                    The reward amount will be paid within 15 working days once
                    the complete & correct remittance details are received by
                    Travelopod.
                  </li>
                  <li>
                    Any data collected for the purpose of this promotion will be
                    processed in accordance with Travelopod’s Privacy Policy.
                  </li>
                  <li>
                    We reserve the right to withdraw the scheme and its benefits
                    at any time and without notice.
                  </li>
                </ul>
                {/* <RedMorelyImg
                  alt="red morley he
                  witt 632745 unsplash"
                /> */}
                {/* <ul>
                  <li>
                    Criteria for eligibility is outlined below;
                    <ul>
                      <li>
                        Booking must be made by a referred party who is not
                        currently known to Travelopod.
                      </li>
                      <li>Complete booking, with payment</li>
                    </ul>
                  </li>
                  <li>
                    Payments under the referral scheme will only be made once a
                    qualifying booking has been made and paid for, within the
                    terms outlined above.
                  </li>
                  <li>
                    Payments will not be made on a referral booking made after
                    the closing date for this scheme.
                  </li>
                  <li>
                    Travelopod will notify you once your referral has made a
                    qualifying booking.
                  </li>
                  <li>
                    We will also inform you should your referral fail to make a
                    qualifying booking within the terms of the promotion.
                  </li>
                  <li>
                    There are no limits to the number of referrals you can make
                  </li>
                  <li>
                    You will be responsible for any tax or social security due,
                    if any, in your tax jurisdiction
                  </li>
                  <li>
                    By agreeing to the Terms and Conditions of this referral
                    scheme, you confirm that you are authorized to supply the
                    contact information for the referred party to Travelopod to
                    be used for marketing of their services.
                  </li>
                  <li>
                    Any personal data collected for the purpose of this
                    promotion will be processed in accordance with the
                    provisions of Travelopod’s Privacy Policy
                  </li>
                  <li>
                    Travelopod Travel standard booking terms and conditions
                    apply to all bookings.
                  </li>
                  <li>
                    We reserve the right to withdraw the scheme and its benefits
                    at any time and without notice.
                  </li>
                </ul> */}
              </div>
            </div>
            <SidebarStaticPage phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(referAndEarnAtTravelopod);
