import React from 'react';

import { normalizeInput } from '../../helpers/utils';
import './style.css';
import { GetCallBackFormSave } from '../../services/apiService';

const GetCallBackForm = () => {
  const [contact, setContact] = React.useState({
    name: {
      label: 'Name*',
      value: '',
      hasError: true,
      errorText: 'Please provide a name',
      validation: val => val !== '',
      onChange: val => val
    },
    email: {
      label: 'Email*',
      value: '',
      hasError: true,
      errorText: 'Please provide a correct email address',
      validation: val => handleInputChange(val),
      onChange: val => val
    },
    contactNumber: {
      label: 'Contact Number*',
      value: '',
      hasError: true,
      errorText: 'Please provide a contact number',
      validation: val => val !== '',
      onChange: (newVal, oldVal) => {
        return normalizeInput(newVal, oldVal);
      }
    },
    bestTimeToCall: {
      label: 'Best Time to Call',
      value: 'Immediately',
      hasError: false,
      validation: val => val,
      onChange: val => val
    },
    yourMessage: {
      label: 'Your Message',
      value: '',
      hasError: false,
      errorText: 'Please provide a Message',
      validation: val => val,
      onChange: val => val
    },
    isShowError: false,
    phoneNumberFormat: ''
  });

  const handleChangeSelect = e =>
    setContact({
      ...contact,
      bestTimeToCall: {
        label: 'Best Time to Call',
        value: e.target.value,
        hasError: false,
        validation: val => val !== ''
      }
    });

  const [displaySuccessMessage, changeDisplaySuccessMessage] = React.useState(
    false
  );

  const handleChange = e => {
    let name = e.target.name;
    let value = e.target.value;
    setContact({
      ...contact,
      [name]: {
        ...contact[name],
        value: contact[name].onChange(value, contact[name].value),
        hasError: !contact[name].validation(value)
      }
    });
  };

  const handleInputChange = val => {
    return /^[^@]+@[^@]+\.[^@]+$/.test(val);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (Object.values(contact).some(({ hasError }) => hasError)) {
      setContact({ ...contact, isShowError: true });
    } else {
      changeDisplaySuccessMessage(!displaySuccessMessage);
      GetCallBackFormSave({ source: 'contactUs', ...contact });
    }
  };

  return (
    <div
      className={`formContact-wrapper ${
        !displaySuccessMessage ? '' : 'success-message'
      }`}
    >
      {!displaySuccessMessage && (
        <>
          <form className="formContact-from" data-subscription-form>
            <div className="submit-block">
              <h4>Get Call Back</h4>
              <div className="submit-wrapper d-flex">
                <div className="input">
                  <label className="label" htmlFor="name">
                    {contact.name.label}
                  </label>
                  <input
                    id="name"
                    className="submit-input"
                    type="text"
                    name="name"
                    value={contact.name.value}
                    onChange={handleChange}
                    required
                  />
                  {contact.isShowError && contact.name.hasError === true && (
                    <p className="error-text">{contact.name.errorText}</p>
                  )}
                </div>
                <div className="input">
                  <label className="label" htmlFor="email">
                    {contact.email.label}
                  </label>
                  <input
                    id="email"
                    className="submit-input"
                    text="email"
                    name="email"
                    value={contact.email.value}
                    onChange={handleChange}
                    placeholder="your.email@mail.com"
                    required
                  />
                  {contact.isShowError && contact.email.hasError === true && (
                    <p className="error-text">{contact.email.errorText}</p>
                  )}
                </div>
                <div className="united d-flex">
                  <div className="input">
                    <label className="label" htmlFor="contactNumber">
                      {contact.contactNumber.label}
                      <input
                        id="contactNumber"
                        className="submit-input"
                        type="tel"
                        name="contactNumber"
                        value={contact.contactNumber.value}
                        onChange={handleChange}
                        required
                        placeholder="+1 123 2451 21"
                      />
                      {contact.isShowError &&
                        contact.contactNumber.hasError === true && (
                          <p className="error-text">
                            {contact.contactNumber.errorText}
                          </p>
                        )}
                    </label>
                  </div>
                  <div className="input">
                    <p className="label" htmlFor="bestTimeToCall">
                      {contact.bestTimeToCall.label}
                      <select
                        name="bestTimeToCall"
                        onChange={handleChangeSelect}
                        value={contact.bestTimeToCall.value}
                      >
                        <option value="Immediately" defaultValue>
                          Immediately
                        </option>
                        <option value="Morning 8 to 12">Morning 8 to 12</option>
                        <option value="Afternoon 12 to 6">
                          Afternoon 12 to 6
                        </option>
                        <option value="Evening 6 to 12">Evening 6 to 12</option>
                      </select>
                    </p>
                  </div>
                </div>
                <div className="input">
                  <p className="label">{contact.yourMessage.label}</p>
                  <textarea
                    id="yourMessage"
                    className="submit-input"
                    text="yourMessage"
                    name="yourMessage"
                    value={contact.yourMessage.value}
                    onChange={handleChange}
                    required
                  />
                  {contact.isShowError &&
                    contact.yourMessage.hasError === true && (
                      <p className="error-text">
                        {contact.yourMessage.errorText}
                      </p>
                    )}
                </div>
                <div className="btn-submit">
                  <button
                    className="button-search"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
      {displaySuccessMessage && (
        <div className="thank-you" data-thank-you="">
          <h3 className="h3">
            Thank you for getting in touch! We'll respond soon.
          </h3>
        </div>
      )}
    </div>
  );
};

export default GetCallBackForm;
